/**@jsx jsx*/
import React, { useState, useMemo } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { useSpring, a } from "react-spring"

import Layout from "../components/layout"
import {
  TitleSecondary,
} from "../components/titles"
import { serverURL } from "../../variables"
import { medMT, smallMT } from "../components/spacing"
import FloatInput from "../components/floatInput"
import { ButtonSecondary } from "../components/buttons"

const STATE = "swagmeister"

const bold = css`
  font-weight: 400;
`
const Content = styled.section`
  ${smallMT}
  grid-column-start: 2;
  grid-row-start: 2;
  z-index: 2;
  display: flex;
  flex-direction: column;
`
const Input = styled(FloatInput)`
  width: 90%;
  max-width: 400px;
`

const Setup = () => {
  const [showPasswordSection, setPasswordSection] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const oldPasswordInput = useMemo(
    () => (
      <Input
        type="text"
        css={smallMT}
        placeholder="Old password"
        onChange={e => setOldPassword(e.target.value)}
      />
    ),
    []
  )
  const newPasswordInput = useMemo(
    () => (
      <Input
        type="text"
        css={smallMT}
        placeholder="New password"
        onChange={e => setOldPassword(e.target.value)}
      />
    ),
    []
  )
  const toggleShow = set => {
    set(prev => !prev)
  }
  const passwordSpring = useSpring({
    height: showPasswordSection ? `180px` : `0px`,
    opacity: showPasswordSection ? `1` : `0`,
  })
  return (
    <Layout>
      <Content>
        <TitleSecondary css={bold}>Settings</TitleSecondary>
        <TitleSecondary
          css={medMT}
          onClick={() => toggleShow(setPasswordSection)}
        >
          Change password
        </TitleSecondary>
        <a.div
          style={passwordSpring}
          css={css`
            overflow: hidden;
          `}
        >
          {oldPasswordInput}
          {newPasswordInput}
          <ButtonSecondary css={smallMT}>Change</ButtonSecondary>
        </a.div>
      </Content>
    </Layout>
  )
}

export default Setup
